import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function Optout(props) {
  const { id } = props.match.params;
  console.log("policyType", id);
  const src =
    id === "CCPA"
      ? `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_CCPA_OPTOUT_MYSELF}`
      : `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_OPTOUT_MYSELF}`;

  const pagId =
    id === "CCPA"
      ? `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_CCPA_OPTOUT_MYSELF_PGID}`
      : `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_OPTOUT_MYSELF_PGID}`;
  return (
    <div>
      <SrrHeader
        heading="Do Not Sell Personal Information"
        breadcrumb_path1="CHURNZERO TRUST CENTER"
        breadcrumb_path2="OPTOUT"
      />

      <section className="form-section">
        <ImportScript src={src} pagId={pagId} />
      </section>
    </div>
  );
}

export default Optout;
