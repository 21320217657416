import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function Delete(props) {
  const { id } = props.match.params;
  console.log("policyType", id);
  const src =
    id === "CCPA"
      ? `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_CCPA_DELETE_MYSELF}`
      : `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_DELETE_MYSELF}`;

  const pagId =
    id === "CCPA"
      ? `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_CCPA_DELETE_MYSELF_PGID}`
      : `${process.env.REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_DELETE_MYSELF_PGID}`;

  console.log("src", src);
  console.log("pagId", pagId);
  return (
    <div>
      <SrrHeader
        heading="Delete Personal Information"
        breadcrumb_path1="CHURNZERO TRUST CENTER"
        breadcrumb_path2="DELETE"
      />

      <section className="form-section">
        <ImportScript src={src} pagId={pagId} />
      </section>
    </div>
  );
}

export default Delete;
