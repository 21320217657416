import { useEffect } from "react";
const ImportScript = (props) => {
  // const [scriptLoaded, setScriptLoaded] = useState(false);

  // useEffect(() => {
  //   if (myRef.current.childNodes.length > 0) {
  //     console.log("myRef", myRef.current.childNodes.length);
  //     props.enableLoader(false);
  //   } else {
  //     props.enableLoader(true);
  //     console.log("myRef", myRef.current.childNodes.length);
  //   }
  // }, [scriptLoaded]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = props.src;
    script.dataset.pageId = props.pageId;
    script.id = "wirewheel-ppp";
    script.dataset.jsonSrc = `${process.env.REACT_APP_CURRENT_ENVIRONMENT_URL}`;
    script.async = true;
    script.crossOrigin = "anonymous";

    document.getElementById("wirewheel").appendChild(script);

    script.onload = function () {
      console.log("script loaded");
      window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
        })
      );
    };
  }, [props]);

  return <div id="wirewheel" className="container"></div>;
};
export default ImportScript;
