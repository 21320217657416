import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TrustCenter from "./components/TrustCenter";
import SubmitRequest from "./components/SubmitRequest";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import Access from "./components/Access";
import Delete from "./components/Delete";
import Optout from "./components/Optout";

import "./App.scss";
import Correction from "./components/Correction";
import Portability from "./components/Portability";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={TrustCenter} />
          <Route path="/submit-request" component={SubmitRequest} />
          <Route path="/:id/access" component={Access} />
          <Route path="/:id/delete" component={Delete} />
          <Route path="/:id/optout" component={Optout} />
          <Route path="/GDPR/correction" component={Correction} />
          <Route path="/GDPR/portability" component={Portability} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
