import React, { useState } from "react";
import { Container, DropdownButton, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SrrHeader from "../shared/components/SrrHeader";
export default SubmitRequest;

function SubmitRequest() {
  const [CCPAActive, setCCPAActive] = useState(true);
  const [GDPRActive, setGDPRActive] = useState(false);
  const [othersActive, setOthersActive] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("CCPA");

  const handleClick = (e) => {
    if (e.target.innerText === "CCPA") {
      setCCPAActive(true);
      setGDPRActive(false);
      setOthersActive(false);
      setDropdownValue("CCPA");
    } else if (e.target.innerText === "GDPR") {
      setCCPAActive(false);
      setGDPRActive(true);
      setOthersActive(false);
      setDropdownValue("GDPR");
    } else {
      setCCPAActive(false);
      setGDPRActive(false);
      setOthersActive(true);
      setDropdownValue("Others");
    }
  };
  return (
    <>
      <SrrHeader
        heading="Submit Request"
        breadcrumb_path1="CHURNZERO TRUST CENTER"
        breadcrumb_path2="SUBMIT DATA SUBJECT REQUEST"
      />

      <Container className="submit-request mt-4">
        <div className="mb-3 mt-4">
          <p>
            We are committed to the responsible collection and use of personal
            information. If you want to access, delete, or opt out of the sale
            of personal information, choose from the drop down below.
          </p>
        </div>

        <section>
          <p>
            <strong>Please select policy type of your interest:</strong>
          </p>

          <DropdownButton
            id="dropdown-basic-button"
            title={dropdownValue}
            className="mb-4"
          >
            <Dropdown.Item onClick={handleClick}>CCPA</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>GDPR</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Others</Dropdown.Item>
          </DropdownButton>
        </section>
        {CCPAActive && (
          <section id="ccpa" className="mb-5">
            <h3>California Consumer Privacy Act</h3>
            <p>
              If you are a California resident, you can make certain requests
              regarding your personal information. We will fulfill each of these
              requests to the extent required by law.
            </p>
            <p>
              <strong>Access</strong> - You have the right to request
              information about the categories and specific pieces of personal
              data we have collected about you, as well as the categories of
              sources from which such information is collected, the purpose for
              collecting such information, and the categories of third parties
              with whom we share such information.
            </p>
            <p>
              <strong>Delete</strong> - You have the right to ask that we delete
              your personal information. Once we receive a request, we will
              delete the personal information (to the extent required by law) we
              hold about you as of the date of your request from our records and
              direct any service providers to do the same. Choosing to delete
              your personal information may impact your ability to use our
              websites and online features, including closure of your online
              account, and limit your use of functions that require your
              personal information.
            </p>
            <p>
              <strong>Opt-Out</strong> - We do not share your personal
              information for money. We do share personal information with
              certain third parties in a way that may be considered a “sale”
              under California law even if we don’t receive money in exchange
              for the information. As a California resident, you have a right to
              opt-out of the sale of your personal information.
            </p>
            <section className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link className="selfLink" to={`CCPA/access`} target="_blank">
                    <Button variant="primary"> Access </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`CCPA/delete`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Delete{" "}
                    </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`CCPA/optout`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Optout{" "}
                    </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}

        {GDPRActive && (
          <section id="gdpr" className="mb-5">
            <h3>General Data Protection Regulation</h3>
            <p>
              If you are a Europe resident, you can make certain requests
              regarding your personal information. We will fulfill each of these
              requests to the extent required by law.
            </p>
            <p>
              <strong>Access</strong> - You have the right to request
              information about the categories and specific pieces of personal
              data we have collected about you, as well as the categories of
              sources from which such information is collected, the purpose for
              collecting such information, and the categories of third parties
              with whom we share such information.
            </p>
            <p>
              <strong>Delete</strong> - You have the right to ask that we delete
              your personal information. Once we receive a request, we will
              delete the personal information (to the extent required by law) we
              hold about you as of the date of your request from our records and
              direct any service providers to do the same. Choosing to delete
              your personal information may impact your ability to use our
              websites and online features, including closure of your online
              account, and limit your use of functions that require your
              personal information.
            </p>

            <section className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link className="selfLink" to={`GDPR/access`} target="_blank">
                    <Button variant="primary"> Access </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`GDPR/delete`} target="_blank">
                    <Button variant="primary"> Delete </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`GDPR/optout`} target="_blank">
                    <Button variant="primary"> Optout </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`GDPR/correction`}
                    target="_blank"
                  >
                    <Button variant="primary"> Correction </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`GDPR/portability`}
                    target="_blank"
                  >
                    <Button variant="primary"> Portability </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}

        {othersActive && (
          <section id="others" className="mb-5">
            <p>
              Thank you for your request! We respect your privacy and appreciate
              your interest. At this time, this service is only provided for
              CCPA and GDPR policy users.
            </p>
            <p>
              You can always review our privacy practices in our{" "}
              <u>
                <a
                  href="https://churnzero.net/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="https://churnzero.net/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </u>
              .
            </p>
          </section>
        )}
      </Container>
    </>
  );
}
