import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import metrics from "../shared/images/metrics.png";
import bannerImg from "../shared/images/trustcenter.png";

function TrustCenter() {
  return (
    <>
      <img
        src={bannerImg}
        alt="banner=img"
        className="img-responsive"
        width="100%"
        height="auto"
      />

      <Container className="my-5">
        <div>
          <p className="text-justify">
            ChurnZero is a company that prioritizes customer trust. We know that
            the security and integrity of customer data is important to our
            customers’ values and operations. We strive to consistently deliver
            superior products and services to our customers while safeguarding
            the privacy of your information.
          </p>
          <p className="text-justify">
            We recognize that protecting privacy requires a holistic security
            program. We want you to have confidence in the way we practice
            security at ChurnZero, and we want to earn your trust as the
            infrastructure for your customer data. Maintaining the security,
            integrity and confidentiality of your data is at the forefront of
            everything we do.
          </p>
          <p className="text-justify">
            Below you can learn more about our privacy and security policies we
            adhere to as well as make a request to access your data.
          </p>
        </div>
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={4} lg={4}>
            <a
              className="card-tc"
              href="https://churnzero.net/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">Our Privacy Policy</p>
            </a>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <a
              className="card-tc"
              href="https://churnzero.net/security/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">Security</p>
            </a>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <a className="card-tc" href="/submit-request">
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">Submit Request</p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TrustCenter;
