import React from "react";

function SrrHeader(props) {
  return (
    <>
      <div className="heading">
        <div className="container">
          <h1>{props.heading}</h1>
        </div>
      </div>
      <div className="sub-heading bg-gray margin-bottom-30" id="request-form">
        <div className="container">
          <ul className="wirewheel-breadcrumb">
            <li>
              <a className="sub-heading--wirewheel-anchor" href="/">
                {props.breadcrumb_path1}
              </a>
            </li>
            <li>
              <a
                className="sub-heading--wirewheel-anchor"
                href={window.location.href}
              >
                {props.breadcrumb_path2}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
