import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function Portability() {
  return (
    <div>
      <SrrHeader
        heading="Update Personal Information"
        breadcrumb_path1="CHURNZERO TRUST CENTER"
        breadcrumb_path2="Portability"
      />

      <section className="form-section">
        <ImportScript
          src={
            process.env.REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_PORTABILITY_MYSELF
          }
          pagId={
            process.env
              .REACT_APP_CHURNZERO_SRR_CONFIG_GDPR_PORTABILITY_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default Portability;
